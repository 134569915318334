import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`12 Days of Christmas WOD`}</strong></p>
    <p>{`1-Ring Dip (RX+ Ring Muscle Up)`}</p>
    <p>{`2-Power Cleans (75/55)(RX+ 95/65)`}</p>
    <p>{`3-Front Squats`}</p>
    <p>{`4-Push Press`}</p>
    <p>{`5-Thrusters`}</p>
    <p>{`6-Box Jumps (24/20)(RX+ 30/24)`}</p>
    <p>{`7-T2B`}</p>
    <p>{`8-Pushups`}</p>
    <p>{`9-V Ups`}</p>
    <p>{`10-KBS (53/35)(RX+ 70/53)`}</p>
    <p>{`11-Goblet Squats (53/35)(RX+ 70/53)`}</p>
    <p>{`12-Step Ups (6/leg, 24/20)`}</p>
    <p><em parentName="p">{`*`}{`Performed like the song: 1, 2-1, 3-2-1, 4-3-2-1, 5-4-3-2-1 etc.`}</em></p>
    <p><strong parentName="p">{`*`}{`Classes will be at 8:30, 9:45, and 11:00am today.`}</strong></p>
    <p><strong parentName="p">{`*`}{`We are closed Friday, Christmas Day.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      